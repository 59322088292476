<style scoped></style>

<template>
  <div style="padding: 10px;display: flex; flex-direction: column;">
    <div style="background-color: #FFF;padding: 20px;border-radius: 5px;margin-bottom: 10px;">
      <Steps style="margin: 0 auto;width: 50%;min-width: 700px;" :current="stepCurrent">
        <Step title="未上传"></Step>
        <Step title="已上传"></Step>
        <Step title="已发布"></Step>
      </Steps>
    </div>
    <router-view style="flex: 1; height: 0;" />
  </div>
</template>

<script>
import { request } from '@/api'

export default {
  data () {
    return {
      data: null
    }
  },
  computed: {
    stepCurrent () {
      const status = this.data ? this.data.status : null
      return ['wait', 'check', 'end'].findIndex(v => v === status)
    },
    monthId () {
      if (this.$route.query.id) {
        return Number(this.$route.query.id)
      }
      return null
    },
    routerName () {
      return this.$route.name
    }
  },
  watch: {
    routerName () {
      if (['performance.wages.manage.detail', 'performance.wages.manage.detail.wait', 'performance.wages.manage.detail.check', 'performance.wages.manage.detail.end'].includes(this.routerName)) {
        if (this.monthId) {
          this.loadData()
        } else {
          this.$router.back()
        }
      }
    }
  },
  methods: {
    async loadData () {
      const data = await request('/performance/wage_month', 'get', {
        id: this.monthId
      })
      if (!data.length) {
        return this.$Modal.warning({
          title: '数据不存在',
          onOk: () => this.$router.back()
        })
      }
      this.data = data[0]
      let routeName = {
        wait: 'performance.wages.manage.detail.wait',
        check: 'performance.wages.manage.detail.check',
        end: 'performance.wages.manage.detail.end'
      }[this.data.status];
      if (routeName && this.$route.name !== routeName) {
        this.$router.replace({
          name: routeName,
          query: {
            id: this.monthId
          },
          params: {
            monthData: this.data
          }
        })
      }
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
